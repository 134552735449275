import React, { useContext, useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import ContactContext from '~/provider/ContactContext'
import SEO from '~/components/seo'
import FunkyShapeCom from '~/components/FunkyShape'
import { Container, GridItem, TwoColumnGrid, StyledSVG } from '../utils/styles'
import Image from 'gatsby-image'

import Funky from '../images/funk1.svg'

function IndexPage() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useContext(ContactContext)
  const [processingMessage, setProcessingMessage] = useState('')

  function wait(ms = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms)
    })
  }

  async function handleSubmit(event) {
    event.preventDefault()
    console.log('submitted')
    setLoading(true)
    setProcessingMessage('Sending message')

    const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/contact`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        message: message,
        email: email,
      }),
    })
    const text = JSON.parse(await res.text())

    if (res.status >= 400 && res.status < 600) {
      setError(text.message)
      setProcessingMessage('Sending failed')
      await wait(2000)
      setLoading(false)
    } else {
      setProcessingMessage('Message sent successfully')
      console.log('Completed successfully: ' + name)
      await wait(2000)
      setLoading(false)
    }
  }

  return (
    <>
      <SEO title="Lucie Conoley | Contact Me"  description="Have a most important place you'd like to see in your home? drop me a message" keywords={[`Mips`, `maps`, `prints`, `contact`]} />

      <Container>
        <h2>CONTACT</h2>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={loading}>
            <label className="label" htmlFor="name">
              Name
            </label>
            <div className="control">
              <input
                type="text"
                className="input"
                name="name"
                id="name"
                placeholder="Your name"
                onBlur={event => setName(event.target.value)}
                required
              />
            </div>
          </fieldset>
          <fieldset disabled={loading}>
            <label className="label" htmlFor="email">
              Email
            </label>
            <div className="control">
              <input
                type="text"
                className="input"
                name="email"
                id="email"
                placeholder="Your email"
                onBlur={event => setEmail(event.target.value)}
                required
              />
            </div>
          </fieldset>
          <fieldset disabled={loading}>
            <label className="label" htmlFor="message">
              Message
            </label>
            <div className="control">
              <textarea
                type="text"
                className="input"
                name="message"
                id="message"
                placeholder="Your message"
                onBlur={event => setMessage(event.target.value)}
                required
              />
            </div>
          </fieldset>
          <fieldset
            className="field is-grouped is-grouped-centered"
            disabled={loading}
          >
            <p className="control">
              <button
                className="button is-medium has-background-info is-primary"
                type="submit"
              >
                {loading ? processingMessage : 'Send'}
              </button>
            </p>
          </fieldset>
          {error && <p className="error">Error: {error}</p>}
        </form>
      </Container>
    </>
  )
}

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "funk1.svg" }) {
          publicURL
        }
        about: file(relativePath: { eq: "lucie-conoley.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        world: file(relativePath: { eq: "the-world.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <TwoColumnGrid>
          <GridItem>
            <h2>Lucie</h2>
            <p>
              I believe most people have a love of maps. Maps tell us where we
              are, where we have been and where we dream to go. We engage
              instantly with the mass of information and make sense of it
              through finding something we can familiarise ourselves with.
            </p>

            <FunkyShapeCom fill={'#DC85B7'}>
              <h2 className="is-1 title">AND IT'S EXCITING!</h2>
            </FunkyShapeCom>
            <p>
              I call my maps ‘The Mips’, The Most Important Places. Maps that
              detail all that makes an area unique. To take that connection you
              have when you pick out a road that you know, even further. Finding
              your local shop, your pub, your house....A tree you fell out of!
            </p>
            <p>I look at them as a record of a moment in time.</p>
            <p>
              I work from my home studio in West Sussex, a haven of chaos, house
              plants and disco. Available for prints, commissions, general
              scribbles.
            </p>
          </GridItem>
          <GridItem>
            <Image
              fluid={data.about.childImageSharp.fluid}
              alt="Lucie Conoley"
              className="right-underlap funky"
            />
          </GridItem>
        </TwoColumnGrid>
        <TwoColumnGrid>
          <GridItem>
            <Image
              fluid={data.world.childImageSharp.fluid}
              alt="Lucie Conoley - The World"
              className="left-underlap funky"
            />
          </GridItem>
          <GridItem>
            <IndexPage />
          </GridItem>
        </TwoColumnGrid>
      </>
    )}
  />
)

export default About
